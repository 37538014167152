<template>
  <div class="card border-radius-9">
    <div class="card-body pb-0">
      <div class="d-flex align-items-center justify-content-between mb-5">
        <h5 class="custom-card-title">İllere Göre Siparişler</h5>
        <span class="btn btn-light btn-sm border-0" v-on:click="onRefresh"
          ><i class="fas fa-sync"></i
        ></span>
      </div>
      <!-- Body -->
      <div v-if="!loading">
        <svg
          version="1.1"
          id="svg-turkiye-haritasi"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 1007.478 527.323"
          xml:space="preserve"
        >
          <g
            v-for="city in data"
            v-bind:key="city.code"
            v-bind:id="city.name + '-' + city.code"
            v-tooltip.bottom="tooltipText(city)"
          >
            <path
              v-for="(path, index) in city.paths"
              v-bind:key="index"
              v-bind:d="path"
              v-bind:fill="getFillColor(city.percent)"
            />
          </g>
        </svg>
      </div>
      <div class="position-relative" style="min-height: 410px" v-else>
        <WeLoading />
      </div>
      <!-- ./Body -->
    </div>
  </div>
</template>
<script>
import { turkeyData } from "./Data/turkey";
import { mapState, mapActions } from "vuex";

export default {
  name: "Choropleth",
  data() {
    return {
      name: "choropleth",
      loading: true,
      data: [],
    };
  },
  methods: {
    ...mapActions("widget", ["refreshReport"]),
    getFillColor(percent) {
      let opacity = percent ? percent / 100 : 0;

      if (!opacity) {
        return "rgba(202, 202, 202, 0.7)";
      }

      opacity += 0.2;

      return `rgba(128, 0, 0, ${opacity})`;
    },
    tooltipText(city) {
      let formattedPrice = localization.getMoneyFormat(null, city.price);

      return `<b>${city.name}</b> <br /> Sipariş Adeti: ${city.count} <br /> Sipariş Tutarı: ${formattedPrice}`;
    },
    onRefresh(showLoader = true) {
      this.loading = showLoader;
      this.refreshReport({
        name: this.name,
        onSuccess: (result) => {
          const items = result.data.items || [];

          this.data = turkeyData.filter((city) => {
            const itemIndex = items.findIndex((item) => item.code == city.code);

            if (itemIndex >= 0) {
              let resultCity = items[itemIndex];

              city.count = resultCity.count;
              city.percent = resultCity.percent;
              city.price = resultCity.price;
            }

            return city;
          });

          this.loading = false;
        },
      });
    },
  },
  computed: {
    ...mapState({
      report: (state) => state.widget.reports,
    }),
  },
  components: {
    turkeyData,
  },
  mounted() {
    this.onRefresh();
  },
  watch: {
    "report.period": function(newVal) {
      if (newVal && newVal !== "custom_date") {
        this.onRefresh(true);
      }
    },
    "report.periodDate": function(newVal) {
      if (newVal && newVal.start && newVal.end) {
        this.onRefresh(true);
      }
    },
  },
};
</script>
<style lang="scss">
path {
  // fill: var(--indigo); // cornflowerblue;
  cursor: pointer;
  -webkit-transition: all 150ms;
  -moz-transition: all 150ms;
  -o-transition: all 150ms;
  transition: all 150ms;
}

path:hover {
  opacity: 0.85;
}

.land {
  fill-opacity: 1;
  stroke: white;
  stroke-opacity: 1;
  stroke-width: 0.5;
}
</style>
